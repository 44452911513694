import Grid from "@mui/material/Grid";
import TextFieldInput from "../Input/TextFieldInput";
import Accordion from "../Accordion/Accordion";
import OptionItem from "./OptionItem";
import Label from "../Label/Label";
import { Optionlist } from "./styles";

export type props = {
  updateTemplate: (element: any) => void;
  template: any;
  toggleOptionPane: () => void;
  handleAccordions: (key: any) => void;
  openedAccordion: number;
};

const TemplateOptions = ({
  template,
  updateTemplate,
  openedAccordion,
  handleAccordions,
  toggleOptionPane,
}: props) => {
  const getValue = (id: any, value: any) => {
    const temp = { ...template, [id]: value };
    updateTemplate(temp);
  };

  // Function to update list on drop
  const handleSelectedFontsClick = (item: any) => {
    const temp = { ...template };
    const index = template.selectedFonts.findIndex(
      (font: any) => font.FontId === item.fontId
    );
    if (
      template.selectedFonts.filter((selectedFont: any) => {
        return selectedFont.FontId === item.fontId;
      }).length > 0
    ) {
      temp.selectedFonts = [
        ...temp.selectedFonts.slice(0, index),
        ...temp.selectedFonts.slice(index + 1),
      ];
      updateTemplate(temp);
    } else {
      temp.selectedFonts = [...temp.selectedFonts, { FontId: item.fontId }];
      updateTemplate(temp);
    }
  };

  // Function to update list on drop
  const handleSelectedColorsClick = (item: any) => {
    const temp = { ...template };
    const index = template.selectedColors.findIndex(
      (fontColor: any) => fontColor.FontColorId === item.fontColorId
    );
    if (
      template.selectedColors.filter((selectedColor: any) => {
        return selectedColor.FontColorId === item.fontColorId;
      }).length > 0
    ) {
      temp.selectedColors = [
        ...temp.selectedColors.slice(0, index),
        ...temp.selectedColors.slice(index + 1),
      ];
      updateTemplate(temp);
    } else {
      temp.selectedColors = [
        ...temp.selectedColors,
        { FontColorId: item.fontColorId },
      ];
      updateTemplate(temp);
    }
  };

  return (
    <Accordion
      summary="Template"
      expanded={openedAccordion === 1}
      summaryClick={() => {
        handleAccordions(1);
        toggleOptionPane();
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextFieldInput
            id="uiTemplateName"
            title="Name"
            multiline={false}
            onChange={(id, value) => getValue(id, value)}
            controlledValue={template}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldInput
            id="width"
            title="Width"
            multiline={false}
            onChange={(id, value) => getValue(id, value)}
            controlledValue={template}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldInput
            id="height"
            title="Height"
            multiline={false}
            onChange={(id, value) => getValue(id, value)}
            controlledValue={template}
          />
        </Grid>
        <Grid item xs={12}>
          <Label id="Options">Font: </Label>
          <Optionlist>
            {template.fonts.map((font: any, key: number) => (
              <OptionItem
                selected={
                  template.selectedFonts.filter(
                    (selectedFont: any) => selectedFont.FontId === font.fontId
                  ).length > 0
                }
                value={font.font}
                key={1}
                onClick={() => handleSelectedFontsClick(font)}
              />
            ))}
          </Optionlist>
        </Grid>
        <Grid item xs={12}>
          <Label id="Options">Color:</Label>
          <Optionlist>
            {template.fontsColors.map((color: any, key: number) => (
              <OptionItem
                selected={
                  template.selectedColors.filter(
                    (selectedColor: any) =>
                      selectedColor.FontColorId === color.fontColorId
                  ).length > 0
                }
                value={color.fontColor}
                key={1}
                onClick={() => handleSelectedColorsClick(color)}
              />
            ))}
          </Optionlist>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default TemplateOptions;
