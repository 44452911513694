import Grid from "@mui/material/Grid";
import TextFieldInput from "../Input/TextFieldInput";
import DropdownInput from "../DropdownInput/DropdownInput";
import { ALIGNMENTS } from "../../pages/itemData";

export type props = {
  active: any;
  updateItem: (key: any, element: any) => void;
  items: any;
  availableColors?: any;
  availableFonts?: any;
  template: any;
};

const Text = ({
  active,
  updateItem,
  items,
  availableColors,
  availableFonts,
  template,
}: props) => {
  const getValue = (id: any, value: any) => {
    const temp = { ...items[active], [id]: value };
    updateItem(active, temp);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextFieldInput
          id="label"
          title="Label"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="width"
          title="Width"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="height"
          title="Height"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="xpos"
          title="X"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="ypos"
          title="Y"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={12}>
        <DropdownInput
          id="pcodeId"
          title="Personalization Key"
          onChange={(id, value) =>
            getValue(
              id,
              template?.pcodes?.filter((pcode: any) => {
                return pcode.pcode === value;
              })[0].pcodeId
            )
          }
          value={
            template?.pcodes?.filter((pcode: any) => {
              return pcode.pcodeId === items[active].pcodeId;
            })[0].pcode
          }
          options={template?.pcodes?.map((pcode: any) => pcode.pcode)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldInput
          id="defaultText"
          title="Default Text"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={12}>
        <DropdownInput
          id="fontId"
          title="Font"
          onChange={(id, value) =>
            getValue(
              id,
              availableFonts.filter((font: any) => font.font === value)[0]
            )
          }
          value={
            availableFonts.filter(
              (font: any) => font.fontId === items[active].fontId.fontId
            )[0]?.font
          }
          options={availableFonts.map((font: any) => font.font)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          id="fontSize"
          title="Font Size"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={6}>
        <DropdownInput
          id="fontColorId"
          title="Font Color"
          onChange={(id, value) =>
            getValue(
              id,
              availableColors.filter((type: any) => type.fontColor === value)[0]
            )
          }
          value={
            availableColors.filter(
              (type: any) =>
                type.fontColorId === items[active].fontColorId.fontColorId
            )[0]?.fontColor
          }
          options={availableColors.map((color: any) => color.fontColor)}
        />
      </Grid>

      <Grid item xs={6}>
        <DropdownInput
          id="textAlign"
          title="Text Align"
          onChange={(id, value) => getValue(id, value)}
          value={items[active].textAlign}
          options={ALIGNMENTS}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          id="charLimit"
          title="Character Limit"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
    </Grid>
  );
};

export default Text;
