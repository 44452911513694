import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Label from "../Label/Label";
import RadioGroup from "../FormGroup/RadioGroup";

interface RadioTextButtonProps {
  id: string;
  title: string;
  options: any[];
  callback: (id: string, value: string) => void;
  defaultValue?: string;
}

const RadioImageButton = ({
  id,
  title,
  options,
  callback,
  defaultValue,
}: RadioTextButtonProps) => {
  const [selected, setSelected] = useState(defaultValue);

  const radioChange = (event: any, value: string) => {
    setSelected(value);
    callback(id, value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      radioChange({}, defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl>
      <Label id="radio-image-label">{title}:</Label>
      <RadioGroup
        aria-labelledby="raio-image-label"
        row
        name="radio-image-buttons-group"
        onChange={radioChange}
        defaultValue={defaultValue}
        value={defaultValue}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.optionItemId}
            value={option.value}
            control={
              <Radio
                checked={option.value === selected ? true : false}
                icon={
                  <img
                    className={`${
                      title.toUpperCase().includes("COLOR")
                        ? "color-radio-btn-icon"
                        : "radio-btn-icon-max-size"
                    }`}
                    src={option.imageFile}
                    alt={option.name}
                  />
                }
                checkedIcon={
                  <img
                    className={`selected-radio-icon ${
                      title.toUpperCase().includes("COLOR")
                        ? "color-radio-btn-icon"
                        : "radio-btn-icon-max-size"
                    }`}
                    src={option.imageFile}
                    alt={option.name}
                  />
                }
              />
            }
            label=""
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioImageButton;
