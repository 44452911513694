import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Editor from "./pages/Editor";
import Builder from "./pages/Builder";

import Theme from "./base/theme";

function App() {
  let params = undefined;
  if (window.location.search !== "") {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  }

  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Editor data={params} />} />
          <Route path="/template-builder" element={<Builder />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
