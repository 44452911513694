import { Fragment } from "react";
import CheckboxInput from "../Checkbox/CheckboxInput";
import RadioImageButton from "../Radio/RadioImageButton";
import RadioTextButton from "../Radio/RadioTextButton";
import SelectInput from "../Select/SelectInput";
import TextFieldInput from "../Input/TextFieldInput";
import UploadImage from "../UploadImage/UploadImage";

export const getInputType = (
  comp: any,
  index: number,
  handleCallback: any,
  handleCheckboxCallback: any,
  handleUploadImageCallback: any
) => {
  switch (comp.type) {
    case "checkbox":
      return (
        <CheckboxInput
          key={index}
          id={comp.id}
          title={comp.title}
          options={comp.options}
          callback={handleCheckboxCallback}
        />
      );
    case "radio":
      return (
        <RadioTextButton
          key={index}
          id={comp.id}
          title={comp.title}
          options={comp.options}
          callback={handleCallback}
        />
      );
    case "radioImage":
      return (
        <RadioImageButton
          key={index}
          id={comp.id}
          title={comp.title}
          options={comp.options}
          callback={handleCallback}
          defaultValue={comp.defaultValue}
        />
      );
    case "select":
      return (
        <SelectInput
          key={index}
          id={comp.id}
          title={comp.title}
          options={comp.options}
          callback={handleCallback}
        />
      );
    case "text":
      return (
        <TextFieldInput
          key={index}
          id={comp.id}
          title={comp.title}
          defaultText={comp.defaultText}
          callback={handleCallback}
          maxLength={comp.maxlength}
          multiline={comp.maxlength > 8}
        />
      );
    case "image":
      return (
        <UploadImage
          key={index}
          id={comp.id}
          title={comp.title}
          callback={handleUploadImageCallback}
          width={comp.width}
          height={comp.height}
        />
      );
    default:
      return <Fragment key={index}></Fragment>;
  }
};
