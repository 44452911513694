import axios from "axios";

const getInitialProductImage = (data: any) => {
  if (data !== null && data !== undefined) {
    return data.productImage;
  }
  return "";
};

const initializeData = (data: any) => {
  return new Promise(function (resolve, reject) {
    if (data !== null && data !== undefined) {
      if (
        data.personalizationData !== null &&
        data.personalizationData !== undefined &&
        data.personalizationData.length > 0
      ) {
        const result = createPersonalizationDataAndComponents(data);
        resolve(result);
      } else if (
        data.marketplaceId !== "" &&
        (data.productId !== "" || data.sku !== "")
      ) {
        const requestData = {
          customizeId: data.customizeId,
          marketplaceId: data.marketplaceId,
          productId: data.productId,
          sku: data.sku,
        };
        return getInitializeData(requestData)
          .then((initializeData: any) => {
            const result =
              createPersonalizationDataAndComponents(initializeData);
            resolve(result);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    }
  });
};

const getInitializeData = (requestData: any) => {
  return new Promise(function (resolve, reject) {
    const url = "https://jcdevapi.cierant.com/initialize";

    console.log("requestData", requestData);

    axios
      .post(url, requestData, {
        headers: {
          Authorization:
            "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
        },
      })
      .then((res: any) => {
        console.log(res.data);
        resolve(JSON.parse(res.data.message)[0]);
      })
      .catch((error) => {
        console.log("error: ", error);
        reject(error);
      });
  });
};

const createPersonalizationDataAndComponents = (data: any) => {
  if (data !== null && data !== undefined) {
    const productImage = getInitialProductImage(data);

    const personalizationDataList: any[] = [];
    const personalizationImageList: any[] = [];
    const productComponentList: any[] = [];
    const personalizationComponentList: any[] = [];
    if (
      data.productOptions !== null &&
      data.productOptions !== undefined &&
      data.productOptions.length > 0
    ) {
      data.productOptions.forEach((option: any) => {
        let pcode = "";
        if (option.hasOwnProperty("pcode") && option.pcode !== "") {
          pcode = option.pcode;

          //Creating the data that will be sent to the api
          const productData = {
            pcodeName: pcode,
            pcodeType: "text",
            pcodeValue: option.selected ? option.selected : "",
            isVAR: option.isVAR,
          };
          personalizationDataList.push(productData);
        } else {
          const productData = {
            pcodeName: option.name,
            pcodeType: "text",
            pcodeValue: option.selected ? option.selected : "",
            isVAR: option.isVAR,
          };
          personalizationDataList.push(productData);
        }

        //Creating the product component that will be displayed on the ui
        let type = option.controlType.toLowerCase();
        if (
          type === "radio" &&
          option.optionItems[0].hasOwnProperty("imageFile") &&
          option.optionItems[0].imageFile !== ""
        ) {
          type = "radioImage";
        }
        const component = {
          id: pcode || option.name,
          type: type,
          title: option.description,
          options: option.optionItems,
          defaultValue: option.selected,
        };
        productComponentList.push(component);
      });
    }
    if (
      data.personalizationData !== null &&
      data.personalizationData !== undefined &&
      data.personalizationData.length > 0
    ) {
      data.personalizationData.forEach((option: any, key: number) => {
        //Creating the data that will be sent to the api
        if (option.pCodeType.toLowerCase() === "image") {
          const personalizeData = {
            pcodeName: option.pcode,
            pcodeType: option.pCodeType,
            pcodeValue: {
              mime: null,
              data: null,
            },
            isVAR: option.isVAR,
          };
          personalizationImageList.push(personalizeData);
        } else {
          const personalizeData = {
            pcodeName: option.pcode,
            pcodeType: option.pCodeType.toLowerCase(),
            pcodeValue: data.pZone[key].defaultText || "",
            isVAR: option.isVAR,
          };
          personalizationDataList.push(personalizeData);
        }

        //Creating the personalization component that will be displayed on the ui
        const defaultText =
          option.defaultText === undefined || option.defaultTest === null
            ? ""
            : option.defaultText;

        let component;
        if (option.pCodeType.toLowerCase() === "image") {
          component = {
            id: option.pcode,
            type: option.pCodeType,
            name: option.pcode,
            title: option.description,
            defaultText: data.pZone[key].defaultText || defaultText,
            pxWidth: data.pZone[key].width,
            pxHeight: data.pZone[key].height,
            width: data.pZone[key].imageWidth,
            height: data.pZone[key].imageHeight,
            x: data.pZone[key].xpos,
            y: data.pZone[key].ypos,
          };
        } else {
          component = {
            defaultText: data.pZone[key].defaultText || defaultText,
            fontColor: data.pZone[key].fontColor,
            fontFamily: data.pZone[key].fontName,
            fontSize: data.pZone[key].fontSize,
            height: data.pZone[key].height,
            id: option.pcode,
            name: option.pcode,
            textAlign: data.pZone[key].textAlign,
            title: option.description,
            type: option.pCodeType,
            width: data.pZone[key].width,
            x: data.pZone[key].xpos,
            y: data.pZone[key].ypos,
            maxlength: data.pZone[key].charLimit,
          };
        }
        personalizationComponentList.push(component);
      });
    }

    const personalizationData = {
      customerId: data.customerId,
      marketplaceId: data.marketplaceId,
      // TODO: This should not be hard coded
      orderId: "25099900",
      sku: data.sku,
      documentId: data.documentId,
      isPreview: true,
      outputFormat: "jpg",
      documentName: data.documentName,
      productId: data.productId,
      customizationData: personalizationDataList,
      customizationImages: personalizationImageList,
      productOptions: data.productOptions,
    };

    const finalizeData = {
      proofMessage: data.proofMessage,
      approvalMessage: data.approvalMessage,
    };

    const templateData = {
      width: data.width,
      height: data.height,
      background: data.background,
      fonts: data.fonts,
      colors: data.fontColors,
    };

    return {
      productImage,
      personalizationData,
      productComponentList,
      personalizationComponentList,
      templateData,
      finalizeData,
    };
  }
};

const ProductDataService = {
  getInitialProductImage,
  initializeData,
  getInitializeData,
  createPersonalizationDataAndComponents,
};

export default ProductDataService;
