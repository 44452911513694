import { getInputType } from "../utils/utils";

export type props = {
  list: any[];
  handleCallback: (id: string, value: string) => void;
  handleCheckboxCallback: (
    id: string,
    optionId: string,
    value: boolean
  ) => void;
  handleUploadImageCallback: (id: string, mime: string, file: string) => void;
};

const ComponentList = ({
  list,
  handleCallback,
  handleCheckboxCallback,
  handleUploadImageCallback,
}: props) => {
  return (
    <>
      {list.map((comp: any, index: number) =>
        getInputType(
          comp,
          index,
          handleCallback,
          handleCheckboxCallback,
          handleUploadImageCallback
        )
      )}
    </>
  );
};

export default ComponentList;
