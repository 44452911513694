import { useState } from "react";
import Grid from "@mui/material/Grid";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldInput from "../Input/TextFieldInput";
import DropdownInput from "../DropdownInput/DropdownInput";
import UploadImage from "../UploadImage/UploadImage";
import Label from "../Label/Label";
import OptionItem from "../Options/OptionItem";
import Button from "../Button/Button";
import OptionsSelection from "../Options/OptionsSelection";

import { StyledOptionsContainer } from "../TabContent/styles";

export type props = {
  product: any;
  updateProduct: (element: any) => void;
  options: any;
  productOptions: any;
  addOption: any;
  updateOption: any;
  selectOption: (element: any) => void;
  selectedOption: number;
  deleteOption: (key: any) => void;
  setTab: (event: any, newValue: number) => void;
  updateOptionsOrder: (element: any) => void;
  templates: any;
  productProperties: any;
};

const Product = ({
  product,
  updateProduct,
  options,
  selectOption,
  selectedOption,
  deleteOption,
  setTab,
  updateOptionsOrder,
  templates,
  productProperties,
  productOptions,
  updateOption,
}: props) => {
  const [edit, setEdit] = useState(false);
  const getValue = (id: any, value: any) => {
    const temp = { ...product, [id]: value };
    updateProduct(temp);
  };

  // Function to update list on drop
  const handleDrop = (e: any) => {
    updateOptionsOrder(e);
  };

  const productOptionsKey = productOptions.map((option: any, key: number) => {
    return { ...option, key: key };
  });

  return (
    <>
      <Grid container item xs={12} spacing={4}>
        <Grid container item xs={12} md={8} spacing={4}>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="productName"
              title="Name"
              multiline={false}
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="sku"
              title="SKU"
              multiline={false}
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="productDescription"
              title="Description"
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="documentId"
              title="Document Id"
              multiline={false}
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DropdownInput
              id="uiTemplateId"
              title="Graphic Editor Template"
              onChange={(id, value) => getValue(id, value)}
              value={
                templates?.filter((template: any) => {
                  return (
                    template.templateId === productProperties?.uiTemplateId
                  );
                })[0]?.templateName || ""
              }
              options={templates.map((template: any, key: number) => {
                const { templateName } = template;
                return { label: templateName, key: key };
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="productImage"
              title="Product Image"
              multiline={false}
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
            {product.productImage && (
              <UploadImage
                initialImage={
                  typeof product.productImage === "string"
                    ? product.productImage
                    : null
                }
                id="productImage"
                title="Product Image"
                callback={() => {}}
                height={1}
                width={1}
                onChange={(id, value) => getValue(id, value)}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldInput
              id="background"
              title="Background Image"
              multiline={false}
              onChange={(id, value) => getValue(id, value)}
              controlledValue={product}
            />
            {product.background && (
              <UploadImage
                initialImage={
                  typeof product.background === "string"
                    ? product.background
                    : null
                }
                id="background"
                title="Background Image"
                callback={() => {}}
                height={1}
                width={1}
                onChange={(id, value) => getValue(id, value)}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4}>
          <Grid xs={12}>
            <StyledOptionsContainer>
              <div className="title-container">
                <Label id="Options">Product Options</Label>
                <Button variant="contained">
                  {edit ? (
                    <CloseIcon onClick={() => setEdit(!edit)} />
                  ) : (
                    <AddIcon onClick={() => setEdit(!edit)} />
                  )}
                </Button>
              </div>
              <Grid item xs={12}>
                <div className="option-list">
                  {edit ? (
                    <OptionsSelection
                      options={options}
                      productOptions={productOptions}
                      updateOption={updateOption}
                    />
                  ) : (
                    <RLDD
                      items={productOptionsKey}
                      itemRenderer={(option: any) => {
                        return (
                          <OptionItem
                            value={option.title}
                            key={1}
                            onClick={() => selectOption(option.id)}
                            selected={selectedOption === option.id}
                            deleteOption={() => deleteOption(option.id)}
                            onEditClick={(e: any) => setTab(e, 2)}
                          />
                        );
                      }}
                      onChange={handleDrop}
                    />
                  )}
                </div>
              </Grid>
            </StyledOptionsContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Product;
