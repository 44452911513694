import Product from "../Product/Product";
import Template from "../Template/Template";
import Options from "../Options/Options";
import Home from "../Home/Home";
import RenderCode from "../RenderCode/RenderCode";

export type props = {
  active: any;
  tab: number;
  product: any;
  updateProduct: (element: any) => void;
  addItem: (item: any) => void;
  productProperties: any;
  items: any;
  setActive: (key: any, element: any) => void;
  updateItem: (key: any, element: any) => void;
  updateTemplate: (element: any) => void;
  template: any;
  options: any;
  productOptions: any;
  addOption: any;
  updateOption: any;
  selectOption: (element: any) => void;
  selectedOption: number;
  deleteOption: (key: any) => void;
  toggleOptionPane: () => void;
  setTab: (event: any, newValue: number) => void;
  homeProperties: any;
  updateHome: (element: any) => void;
  updateOptionsOrder: (element: any) => void;
  deleteItem: (key: any) => void;
  templates: any;
  addProductOption: any;
  updateProductOption: any;
  deleteProductOption: (key: any) => void;
  code?: any;
};

const TabContent = ({
  active,
  tab,
  product,
  updateProduct,
  addItem,
  productProperties,
  items,
  setActive,
  updateItem,
  updateTemplate,
  template,
  options,
  addOption,
  updateOption,
  selectOption,
  selectedOption,
  deleteOption,
  toggleOptionPane,
  setTab,
  homeProperties,
  updateHome,
  updateOptionsOrder,
  deleteItem,
  templates,
  productOptions,
  addProductOption,
  updateProductOption,
  deleteProductOption,
  code,
}: props) => {
  return (
    <>
      {tab === 0 && (
        <Home homeProperties={homeProperties} updateHome={updateHome} />
      )}
      {tab === 1 && (
        <Product
          product={product}
          updateProduct={updateProduct}
          options={options}
          addOption={addProductOption}
          updateOption={updateProductOption}
          selectOption={selectOption}
          selectedOption={selectedOption}
          deleteOption={deleteProductOption}
          setTab={setTab}
          updateOptionsOrder={updateOptionsOrder}
          templates={templates}
          productProperties={productProperties}
          productOptions={productOptions}
        />
      )}
      {tab === 2 && (
        <Options
          addOption={addProductOption}
          updateOption={updateOption}
          selectOption={selectOption}
          selectedOption={selectedOption}
          productOptions={productOptions}
          options={options}
        />
      )}
      {tab === 3 && (
        <Template
          active={active}
          addItem={addItem}
          productProperties={productProperties}
          setActive={setActive}
          items={items}
          updateItem={updateItem}
          title={productProperties.name || "Bar Name"}
          width={template.width}
          height={template.height}
          updateTemplate={updateTemplate}
          template={template}
          toggleOptionPane={toggleOptionPane}
          deleteItem={deleteItem}
        />
      )}
      {tab === 4 && <RenderCode code={code} />}
    </>
  );
};

export default TabContent;
