import { Rnd } from "react-rnd";

export type props = {
  item: any;
  updateItem: (key: any, element: any) => void;
  key: number;
  id: number;
  children: any;
  active?: boolean;
};

const MovableElement = ({ item, updateItem, children, id, active }: props) => {
  const { width, height, xpos, ypos } = item;
  return (
    <Rnd
      size={{
        width,
        height,
      }}
      position={{
        x: xpos,
        y: ypos,
      }}
      onDragStop={(e, d) => {
        updateItem(id, { ...item, xpos: d.x, ypos: d.y });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        updateItem(id, {
          ...item,
          width: ref.style.width,
          height: ref.style.height,
        });
      }}
    >
      {children}
    </Rnd>
  );
};

export default MovableElement;
