import React from "react";
import { StyledText } from "./styles";
export type props = {
  children: React.ReactNode;
  onClick: () => void;
  item: any;
  active?: boolean;
  color: string;
  font: string;
};
const TextElement = ({
  children,
  onClick,
  item,
  active,
  color,
  font,
}: props) => {
  const { fontSize, textAlign } = item;
  return (
    <StyledText
      align={textAlign}
      color={color}
      fontFamily={font}
      fontSize={fontSize}
      onClick={onClick}
      active={active}
    >
      {children}
    </StyledText>
  );
};

export default TextElement;
