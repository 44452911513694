import { useState } from "react";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { Typography } from "@mui/material";
import Accordion from "../Accordion/Accordion";
import ComponentList from "../ComponentList/ComponentList";
import Button from "../Button/Button";
import CheckboxInput from "../Checkbox/CheckboxInput";
import { StyledContainer } from "./styles";
import StaticError from "../ErrorMessage/StaticError";

interface finalizeDataProps {
  proofMessage: string;
  approvalMessage: string;
}

export type props = {
  productsComponentList: any;
  personalizationComponentList: any;
  handleCallback: any;
  handleUploadImageCallback: any;
  handleCheckboxCallback: any;
  handleProf?: any;
  handleSave?: any;
  finalizeData?: finalizeDataProps;
  graphicMode?: boolean;
};

const CustomizationPanel = ({
  productsComponentList,
  personalizationComponentList,
  handleCallback,
  handleUploadImageCallback,
  handleCheckboxCallback,
  handleProf,
  handleSave,
  finalizeData,
  graphicMode,
}: props) => {
  const [disabled, setDisabled] = useState(true);
  const [panelState, setPanelState] = useState([false, false, false]);

  const handleAccordions = (key: number): void => {
    const updatedPanels = panelState.map((accordion, i) => {
      if (i === key) return !accordion;
      return false;
    });
    setPanelState(updatedPanels);
  };

  return (
    <Grid item xs={12} md={6}>
      <StyledContainer className={"information-container"}>
        {productsComponentList.length === 0 && (
          <StaticError message="An Error Occured" />
        )}
        {productsComponentList.length > 0 && (
          <>
            <Accordion
              summary="Select Option"
              expanded={panelState[0]}
              summaryClick={() => handleAccordions(0)}
            >
              <ComponentList
                list={productsComponentList}
                handleCallback={handleCallback}
                handleCheckboxCallback={handleCheckboxCallback}
                handleUploadImageCallback={handleUploadImageCallback}
              />
            </Accordion>
            {!graphicMode && (
              <Accordion
                summary="Personalize"
                expanded={panelState[1]}
                summaryClick={() => handleAccordions(1)}
              >
                <ComponentList
                  list={personalizationComponentList}
                  handleCallback={handleCallback}
                  handleCheckboxCallback={handleCheckboxCallback}
                  handleUploadImageCallback={handleUploadImageCallback}
                />
              </Accordion>
            )}
            <Accordion
              summary="Finalize"
              expanded={panelState[2]}
              summaryClick={() => handleAccordions(2)}
            >
              <div className="prof-container">
                <div className="button-container">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleProf}
                  >
                    View Proof
                  </Button>
                </div>
                <Typography variant="body1">
                  {finalizeData?.proofMessage}
                </Typography>
              </div>
              <Divider className="divider" />
              <div className="prof-container">
                <div className="button-container">
                  <CheckboxInput
                    id="approve"
                    options={[
                      {
                        optionItemId: "approve",
                        name: "Approve",
                      },
                    ]}
                    callback={() => setDisabled(!disabled)}
                  />
                </div>
                <Typography variant="body1">
                  {finalizeData?.approvalMessage}
                </Typography>
              </div>
              <div className="save-container">
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Accordion>
          </>
        )}
      </StyledContainer>
    </Grid>
  );
};

export default CustomizationPanel;
