import { useRef } from "react";
import Modal from "@mui/material/Modal";
import { PinturaEditor } from "react-pintura";
import { getEditorDefaults } from "pintura";
import { StyledContainer } from "./styles";
import "pintura/pintura.css";

export type editorProps = {
  image: string;
  onProcess: (e: any) => void;
  onClose: () => void;
};

const Editor = ({ image, onProcess, onClose }: editorProps) => {
  const componentRef = useRef(null);
  // get default properties
  const editorConfig = getEditorDefaults();

  return (
    <Modal
      open={true}
      onClose={() => console.log("close")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledContainer>
        <PinturaEditor
          {...editorConfig}
          src={image}
          imageCropAspectRatio={1}
          ref={componentRef}
          onProcess={onProcess}
          onClose={onClose}
        ></PinturaEditor>
      </StyledContainer>
    </Modal>
  );
};

export default Editor;
