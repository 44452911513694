import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Accordion from "../Accordion/Accordion";
import Button from "../Button/Button";
import OptionsForm from "./OptionsForm";
import DeleteIcon from "@mui/icons-material/Delete";
import TextFieldInput from "../Input/TextFieldInput";
import Switch from "../Switch/Switch";
import UploadImage from "../UploadImage/UploadImage";

import { HeaderContainer, AccordionTitle } from "./styles";

export type props = {
  addOption: any;
  updateOption: any;
  selectOption: (element: any) => void;
  selectedOption: number;
  productOptions: any;
  options: any;
};

const OptionEditor = ({ updateOption, selectedOption, options }: props) => {
  if (
    options.length === 0 ||
    selectedOption === null ||
    selectedOption > options.length - 1
  )
    return null;

  const filteredOption = options.filter(
    (option: any) => option.id === selectedOption
  )[0];

  if (!filteredOption) return null;

  const index = options.findIndex(
    (option: any) => option.id === selectedOption
  );

  const AddOptionItem = (key: any, lenght: any) => {
    const temp = { ...options[key] };
    temp?.items?.push({
      title: "Item Name",
      value: "",
      cost: "",
      unit: false,
      image: "",
      disable: "",
    });
    updateOption(temp);
  };

  const getItemValues = (id: any, value: any, key: any) => {
    const temp = { ...options[index] };
    temp.items[key][id] = value;
    updateOption(temp);
  };

  const handleDelete = (e: any, id: number) => {
    e.stopPropagation();
    const temp = { ...options[index] };
    temp.items = [
      ...options[index].items.slice(0, id),
      ...options[index].items.slice(id + 1),
    ];
    updateOption(temp);
  };

  const AccordionSummary = ({ item, id }: any) => (
    <AccordionTitle>
      {item.name}
      <DeleteIcon onClick={(e) => handleDelete(e, id)} className="icon" />
    </AccordionTitle>
  );

  function addZeroes(num: any) {
    const number = `${num.cost}`;
    const splitNumber = number.split(".");
    if (splitNumber.length > 1) {
      if (number.split(".").length < 0) {
        num.cost = number + ".00";
      } else if (number.split(".")[1].length === 1) {
        num.cost = number + "0";
      }
    } else {
      num.cost = number + ".00";
    }
    return num;
  }

  return (
    <Grid container item spacing={4} xs={12}>
      <Grid item xs={12} md={12} lg={12}>
        {selectedOption !== null && selectedOption <= options.length - 1 && (
          <>
            <Grid item xs={12}>
              <HeaderContainer>
                <Typography variant="h3">{filteredOption.title}</Typography>
              </HeaderContainer>
            </Grid>
            <OptionsForm option={filteredOption} updateOption={updateOption} />
            <div className="items">
              <p>Option Items:</p>
              {filteredOption?.items?.map((item: any, i: any) => (
                <Grid item xs={12}>
                  <Accordion summary={<AccordionSummary item={item} id={i} />}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TextFieldInput
                          id="name"
                          title="Title"
                          multiline={false}
                          onChange={(id, value) => getItemValues(id, value, i)}
                          controlledValue={filteredOption.items[i]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldInput
                          id="value"
                          title="Value"
                          multiline={false}
                          onChange={(id, value) => getItemValues(id, value, i)}
                          controlledValue={filteredOption.items[i]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldInput
                          id="cost"
                          title="Aditional Cost"
                          multiline={false}
                          onChange={(id, value) => getItemValues(id, value, i)}
                          controlledValue={addZeroes(filteredOption.items[i])}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Switch
                          id="isUnit"
                          label="Per Unit"
                          onChange={(id, value) => getItemValues(id, value, i)}
                          value={filteredOption.items[i]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldInput
                          id="imageFile"
                          title="Image Path"
                          multiline={false}
                          onChange={(id, value) => getItemValues(id, value, i)}
                          controlledValue={filteredOption.items[i]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <UploadImage
                          id="image"
                          title="Image"
                          callback={() => {}}
                          height={1}
                          width={1}
                          onChange={(id, value) => getItemValues(id, value, i)}
                          initialImage={filteredOption.items[i].imageFile}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Switch
                          id="disabled"
                          label="Disable"
                          onChange={(id, value) => getItemValues(id, value, i)}
                          value={filteredOption.items[i]}
                        />
                      </Grid>
                    </Grid>
                  </Accordion>
                </Grid>
              ))}
            </div>
            <Button
              onClick={() =>
                AddOptionItem(selectedOption, options[selectedOption].lenght)
              }
            >
              + Add Item
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default OptionEditor;
