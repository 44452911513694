import Tooltip from "@mui/material/Tooltip";
import SingleComponent from "./SingleComponent";
import { StyledContainer } from "./styles";

export type props = {
  addItem: (item: any) => void;
};

const ComponentsPane = ({ addItem }: props) => {
  return (
    <StyledContainer>
      <Tooltip title="Add Text Area" placement="right-end">
        <SingleComponent
          name={"Text"}
          type={"text"}
          onClick={() => addItem("Text")}
        />
      </Tooltip>
      <Tooltip title="Add Image Area" placement="right-end">
        <SingleComponent
          name={"Image"}
          image={"https://via.placeholder.com/640x360"}
          type={"image"}
          onClick={() => addItem("Image")}
        />
      </Tooltip>
    </StyledContainer>
  );
};

export default ComponentsPane;
