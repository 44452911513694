import MovableElement from "./MovableElement";
import TextElement from "./TextElement";
import ImageElement from "./ImageElement";
import DropdownInput from "../DropdownInput/DropdownInput";
import { StyledContainer, TemplateContainer } from "./styles";

export type props = {
  active: number;
  productProperties: any;
  items: any;
  setActive: (key: any, element: any) => void;
  title: string;
  updateItem: (key: any, element: any) => void;
  width: string;
  height: string;
  setOpenedAccordion: (key: any) => void;
  template: any;
  updateTemplate: (element: any) => void;
  availableColors: any;
  availableFonts: any;
};
const BarPanel = ({
  active,
  setActive,
  items,
  updateItem,
  productProperties,
  width,
  height,
  setOpenedAccordion,
  template,
  updateTemplate,
  availableColors,
  availableFonts,
}: props) => {
  return (
    <StyledContainer>
      <DropdownInput
        id="selectedProduct"
        title="Preview Product Background Selector"
        onChange={(id, value) =>
          updateTemplate({
            ...template,
            selectedProduct: template?.products?.filter(
              (product: any) => product.productName === value
            )[0],
          })
        }
        value={template?.selectedProduct?.productName}
        options={
          template?.products?.map((product: any) => product.productName) || []
        }
      />
      <TemplateContainer width={width} height={height}>
        {template?.selectedProduct?.background ||
        productProperties.background.img ||
        productProperties.background ? (
          <img
            src={template?.selectedProduct?.background}
            alt=""
            className="background"
          />
        ) : null}
        {items.map((item: any, key: number) => {
          return (
            <MovableElement
              item={item}
              updateItem={updateItem}
              key={key}
              id={key}
            >
              {item.type === "text" ? (
                <TextElement
                  onClick={() => {
                    setActive(key, item);
                    setOpenedAccordion(2);
                  }}
                  item={item}
                  active={active === key}
                  color={
                    availableColors.filter(
                      (type: any) =>
                        type.fontColorId === item.fontColorId.fontColorId
                    )[0]?.fontColor
                  }
                  font={
                    availableFonts.filter(
                      (font: any) => font.fontId === item.fontId.fontId
                    )[0]?.font
                  }
                >
                  {item.label}
                </TextElement>
              ) : (
                <ImageElement
                  onClick={() => {
                    setActive(key, item);
                    setOpenedAccordion(3);
                  }}
                  active={active === key}
                ></ImageElement>
              )}
            </MovableElement>
          );
        })}
      </TemplateContainer>
    </StyledContainer>
  );
};

export default BarPanel;
