import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Divider } from "@mui/material";

export type props = {
  value: any;
  onClick: () => void;
  selected: boolean;
};

const OptionItem = ({ value, onClick, selected }: props) => {
  const labelId = `checkbox-list-label-${value}`;
  return (
    <>
      <ListItem key={value} onClick={onClick} disablePadding>
        <ListItemButton role={undefined} dense>
          <Checkbox
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
          <ListItemText id={labelId} primary={value} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};

export default OptionItem;
