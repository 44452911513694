import styled from "@emotion/styled";
import Theme from "../base/theme";

export const StyledContainer = styled.div`
  .custom-container {
    display: flex;
    max-width: ${Theme.breakpoints.values.xl}px;
    padding: 0 ${Theme.spacing(3)};
    width: calc(100% - 16px);

    @media (min-width: ${Theme.breakpoints.values.md}px) {
      margin: auto;
      width: calc(100% - 18px);
    }
  }

  .title-container {
    display: flex;
  }

  .content {
    padding: ${Theme.spacing(3)};
  }

  .header {
    margin-bottom: ${Theme.spacing(4)};
    background-color: ${Theme.palette.grey[100]};
  }
`;
