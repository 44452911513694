import { useState } from "react";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ComponentsPane from "../ComponentsPane/ComponentsPane";
import BarPanel from "../BarPanel/BarPanel";
import TemplateOptions from "../ProductPropertiesPanel/TemplateOptions";
import Accordion from "../Accordion/Accordion";
import Text from "../ProductPropertiesPanel/Text";
import Image from "../ProductPropertiesPanel/Image";
import { AccordionTitle } from "../TabContent/styles";

export type props = {
  active: any;
  addItem: (item: any) => void;
  productProperties: any;
  items: any;
  setActive: (key: any, element: any) => void;
  title: string;
  updateItem: (key: any, element: any) => void;
  width: string;
  height: string;
  updateTemplate: (element: any) => void;
  template: any;
  toggleOptionPane: () => void;
  deleteItem: (key: any) => void;
};

const Template = ({
  active,
  addItem,
  productProperties,
  items,
  setActive,
  updateItem,
  width,
  height,
  updateTemplate,
  template,
  toggleOptionPane,
  deleteItem,
}: props) => {
  const [openedAccordion, setOpenedAccordion] = useState(1);

  const handleAccordions = (key: number) => {
    if (openedAccordion === key) {
      setOpenedAccordion(-1);
    } else {
      setOpenedAccordion(key);
    }
  };

  const handleDelete = (e: any, key: number) => {
    e.stopPropagation();
    deleteItem(key);
  };

  const AccordionSummary = ({ item, title }: any) => (
    <AccordionTitle>
      {title}
      <DeleteIcon onClick={(e) => handleDelete(e, active)} />
    </AccordionTitle>
  );

  const availableColors: any = [];
  template.fontsColors.map((color: any, key: number) => [
    ...template.selectedColors.filter((selectedColor: any) => {
      if (selectedColor.FontColorId === color.fontColorId) {
        availableColors.push(color);
      }
    }),
  ]);

  const availableFonts: any = [];
  template.fonts.map((font: any, key: number) => [
    ...template.selectedFonts.filter((selectedFont: any) => {
      if (selectedFont.FontId === font.fontId) {
        availableFonts.push(font);
      }
    }),
  ]);

  return (
    <Grid container xs={12} spacing={4}>
      <Grid item xs={12} md={2} lg={1}>
        <ComponentsPane addItem={addItem} />
      </Grid>
      <Grid item xs={12} md={5} lg={6}>
        <BarPanel
          active={active}
          productProperties={productProperties}
          setActive={setActive}
          items={items}
          updateItem={updateItem}
          title={template.uiTemplateName || ""}
          width={width}
          height={height}
          setOpenedAccordion={setOpenedAccordion}
          template={template}
          updateTemplate={updateTemplate}
          availableColors={availableColors}
          availableFonts={availableFonts}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <TemplateOptions
          updateTemplate={updateTemplate}
          template={template}
          handleAccordions={handleAccordions}
          toggleOptionPane={toggleOptionPane}
          openedAccordion={openedAccordion}
        />
        {items?.length > 0 && active >= 0 && (
          <>
            {items[active].type === "text" && (
              <Accordion
                summary={<AccordionSummary title="Text Properties" />}
                expanded={openedAccordion === 2}
                summaryClick={() => {
                  handleAccordions(2);
                  toggleOptionPane();
                }}
              >
                <Text
                  active={active}
                  updateItem={updateItem}
                  items={items}
                  availableColors={availableColors}
                  availableFonts={availableFonts}
                  template={template}
                />
              </Accordion>
            )}
            {items[active].type === "image" && (
              <Accordion
                summary={<AccordionSummary title="Image Properties" />}
                expanded={openedAccordion === 3}
                summaryClick={() => {
                  handleAccordions(3);
                  toggleOptionPane();
                }}
              >
                <Image
                  active={active}
                  updateItem={updateItem}
                  items={items}
                  template={template}
                />
              </Accordion>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Template;
