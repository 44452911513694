import React from "react";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Button from "../Button/Button";

export type props = {
  title?: any;
  options?: any[];
  action?: any;
};

const SingleMenu = ({ title, options, action }: props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (option: any) => {
    if (option.action) {
      if (option.value) {
        option.action(`#${option.value}`);
      } else {
        option.action(option.name);
      }
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="menu"
          >
            {title}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuList>
              {options?.map((option, key) => {
                return (
                  <MenuItem onClick={() => handleClose(option)} key={key}>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText>{option.name}</ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default SingleMenu;
