import { useEffect } from "react";
import FormGroup from "../FormGroup/FormGroup";
import Label from "../Label/Label";
import { StyledInput, StyledAutocomplete } from "./styles";

interface DropdownInputProps {
  id: string;
  onChange: (id: string, value: string) => void;
  title?: string;
  options: any;
  value: string;
}

const DropdownInput = ({
  id,
  title,
  onChange,
  options,
  value,
}: DropdownInputProps) => {
  useEffect(() => {}, [value]);

  const handleChange = (e: any, value: any) => {
    onChange(id, value);
  };
  return (
    <FormGroup>
      {title && <Label id="text-field-label">{title}:</Label>}
      <StyledAutocomplete
        disablePortal
        options={options}
        id={id}
        className={"without-padding"}
        onChange={handleChange}
        defaultValue={value}
        value={value}
        renderInput={(params: any) => <StyledInput {...params} value={value} />}
      />
    </FormGroup>
  );
};

export default DropdownInput;
