import { forwardRef, LegacyRef } from "react";
import { Typography } from "@mui/material";
import { StyledComponentContainer } from "./styles";
import ImageIcon from "@mui/icons-material/Image";

export type props = {
  name: string;
  image?: string;
  type: string;
  onClick: () => void;
};

const SingleComponent = forwardRef(
  (props: props, ref: LegacyRef<HTMLDivElement>) => {
    const { name, type } = props;
    return (
      <StyledComponentContainer ref={ref} {...props}>
        {type === "text" ? (
          <>
            <Typography variant="h3" fontWeight={500}>
              T
            </Typography>
            <Typography variant="body2">{name}</Typography>
          </>
        ) : (
          <>
            <ImageIcon fontSize="large" />
            <Typography variant="body2">{name}</Typography>
          </>
        )}
      </StyledComponentContainer>
    );
  }
);

export default SingleComponent;
