import React, { FC, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import Theme from "../../base/theme";

interface ContainerProps {
  width: string;
  height: string;
}

export const StyledContainer = styled.div`
  border-radius: ${Theme.shape.borderRadius}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 300px;

  h3{
    margin-bottom: ${Theme.spacing(4)}}
  }

  .bar {
    border: 1px solid ${Theme.palette.common.black};
    width: 600px;
    height: 200px;
    margin-bottom: ${Theme.spacing(4)};
  }
`;

export const TemplateContainer: FC<
  ContainerProps & HTMLAttributes<HTMLDivElement>
> = styled.div`
  position: relative;
  width: ${(props: ContainerProps) => `${props.width}px;`}
  height: ${(props: ContainerProps) => `${props.height}px;`}
  border: 1px solid ${Theme.palette.grey[300]};

  img {
    width: 100%;
    height: 100%;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
interface TextProps {
  fontFamily: string;
  fontSize: string;
  color: string;
  align: string;
  children: React.ReactNode;
  active?: boolean;
}

export const StyledText: FC<
  TextProps & HTMLAttributes<HTMLDivElement>
> = styled.div`
  border-radius: ${Theme.shape.borderRadius}px;
  border: 1px solid
    ${(props: TextProps) => {
      if (props.active) return "yellow";
      return `#d3d3d3`;
    }};
  color: ${(props: TextProps) => props.color};
  font-family: ${(props: TextProps) => props.fontFamily};
  font-size: ${(props: TextProps) => props.fontSize}px;
  height: 100%;
  padding: ${Theme.spacing(1)};
  text-align: ${(props: TextProps) => props.align};
  width: 100%;
`;

interface ImageProps {
  onClick: () => void;
  active?: boolean;
}

export const StyledImage: FC<
  ImageProps & HTMLAttributes<HTMLDivElement>
> = styled.div`
  border: 1px solid ${Theme.palette.common.black};
  background-color: ${Theme.palette.grey[300]};
  border-radius: ${Theme.shape.borderRadius}px;
  padding: ${Theme.spacing(1)};
  width: 100%;
  height: 100%;
  border: 1px solid
    ${(props: ImageProps) => {
      if (props.active) return "yellow";
    }};

  img {
    width: 100%;
  }
`;
