import ReactJson from "react-json-view";
import Grid from "@mui/material/Grid";

export type props = {
  code: any;
};

const RenderCode = ({ code }: props) => {
  return (
    <Grid item xs={12} md={6}>
      <ReactJson src={code} theme="monokai" />
    </Grid>
  );
};

export default RenderCode;
