import { useState, useEffect, useCallback, useRef, createContext } from "react";
import axios from "axios";
import { cloneDeep } from "lodash";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";

import ProductPreview from "../components/ProductPreview/ProductPreview";
import CustomizationPanel from "../components/CustomizationPanel/CustomizationPanel";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import LoadScreen from "../components/loader/LoadScreen";
import GraphicEditor from "../components/GraphicEditor/GraphicEditor";

import ProductDataService from "../services/ProductDataService";

import PersonalizationData from "../models/PersonalizationData";
import UploadedImage from "../models/UploadedImage";
import RenderCode from "../components/RenderCode/RenderCode";

import EditorStyles from "../components/Editor/EditorStyles";
import "../styles.css";
import Button from "../components/Button/Button";

import mockDataEmpty from "../testing/mock-initialization-data-empty.json";

const emptyArray: any[] = [];

type errorContextProp = {
  errorState: {
    show: boolean;
    message: string;
  };
  setErrorState: any;
};

export const ErrorContext = createContext<errorContextProp | null>(null);

function Editor(props: any) {
  const { data } = props;
  const [personalizationData, setPersonalizationData] = useState({
    customerId: "",
    customizationData: emptyArray,
    customizationImages: emptyArray,
    documentId: "",
    isPreview: "",
    marketplaceId: "",
    orderId: "",
    outputFormat: "",
    sku: "",
    documentName: "",
    productId: "",
    productOptions: [],
  });
  const [productComponentList, setProductComponentList] = useState(emptyArray);
  const [personalizationComponentList, setPersonalizationComponentList] =
    useState(emptyArray);
  const [imageUrl, setImageUrl] = useState("");
  const [callbackData, setCallbackData] = useState({ id: "", value: "" });
  const [callbackImage, setCallbackImage] = useState({
    id: "",
    mime: "",
    file: "",
  });
  const uploadedImages = useRef<UploadedImage[]>([]);
  const [errorState, setErrorState] = useState({
    show: false,
    message: "",
  });
  const [finalizeData, setFinalizeData] = useState({
    proofMessage: "",
    approvalMessage: "",
  });

  const [loading, setLoading] = useState(true);
  const [imageLoading, setimageLoading] = useState(false);
  const [templateData, setTemplateData] = useState({
    width: 0,
    height: 0,
    background: "",
  });
  const [graphicMode, setGraphicMode] = useState(false);

  useEffect(() => {
    var elmnt = document.querySelectorAll("body")[0];
    elmnt.style.overflow = "hidden";
  });

  useEffect(() => {
    console.log("data", data);
    ProductDataService.initializeData(data)
      .then((res: any) => {
        setPersonalizationData(res.personalizationData);
        setImageUrl(res.productImage);
        setProductComponentList(res.productComponentList);
        setPersonalizationComponentList(res.personalizationComponentList);
        setFinalizeData(res.finalizeData);
        setLoading(false);
        setTemplateData(res.templateData);
      })
      .catch((error: any) => {
        setErrorState({
          show: true,
          message: "An error ocurred.",
        });
      });
  }, [data]);

  const [showCode, setShowCode] = useState(false);

  // TODO: Will need to be updated when case arises
  const handleCheckboxCallback = (
    id: string,
    optionId: string,
    value: boolean
  ) => {};

  const handleCallback = useCallback((id: string, value: string) => {
    setCallbackData({ id, value });
  }, []);

  const handleUploadImageCallback = useCallback(
    (id: string, mime: string, file: string) => {
      if (uploadedImages.current.length > 0) {
        const imageIndex = uploadedImages.current.findIndex(
          (el) => el.file === file
        );
        if (imageIndex !== -1) {
          let mime = "text/plain";
          let fileName = uploadedImages.current[imageIndex].name;
          if (fileName === "") {
            fileName = uploadedImages.current[imageIndex].file;
            mime = uploadedImages.current[imageIndex].mime;
          }

          setCallbackImage({ id, mime, file: fileName });
        } else {
          uploadedImages.current.push({ id, mime, file, name: "" });
          setCallbackImage({ id, mime, file });
        }
      } else {
        uploadedImages.current.push({ id, mime, file, name: "" });
        setCallbackImage({ id, mime, file });
      }
    },
    []
  );

  useEffect(() => {
    if (callbackData.id !== "" && callbackData.value !== "") {
      const newData = Object.assign({}, personalizationData);
      newData.customizationData = newData.customizationData.map((el) =>
        el.pcodeName === callbackData.id
          ? { ...el, pcodeValue: callbackData.value }
          : el
      );
      setCallbackData({ id: "", value: "" });

      setPersonalizationData(newData);
    }
  }, [callbackData, personalizationData]);

  useEffect(() => {
    if (callbackImage?.id !== "" && callbackImage?.file !== "") {
      const newData = Object.assign({}, personalizationData);
      newData.customizationImages = newData.customizationImages.map((el) =>
        el.pcodeName === callbackImage.id
          ? {
              ...el,
              pcodeValue: {
                mime: callbackImage.mime,
                data: callbackImage.file,
              },
            }
          : el
      );
      setCallbackImage({ id: "", mime: "", file: "" });
      setPersonalizationData(newData);
    }
  }, [callbackImage, personalizationData]);

  const getImage = () => {
    if (personalizationData !== null && personalizationData !== undefined) {
      const isEmpty = personalizationData?.customizationData.find(
        (el) => el.pcodeValue !== ""
      );
      const imagesToPersonalize =
        personalizationData?.customizationImages.filter(
          (el) => el.pcodeValue.data !== null
        );
      if (
        (isEmpty !== null && isEmpty !== undefined) ||
        imagesToPersonalize.length > 0
      ) {
        const personalizationDataApi: PersonalizationData =
          cloneDeep(personalizationData);
        imagesToPersonalize?.forEach((el) => {
          const uploadedImage = uploadedImages.current.find(
            (img) => img.file === el.pcodeValue.data
          );
          if (
            uploadedImage !== null &&
            uploadedImage !== undefined &&
            uploadedImage.name !== ""
          ) {
            el.pcodeValue.mime = "text/plain";
            el.pcodeValue.data = uploadedImage.name;
          }
          personalizationDataApi?.customizationData.push(el);
        });

        personalizationDataApi.isPreview = false;
        personalizationDataApi.outputFormat = "pdf";
        setimageLoading(true);
        const url =
          "https://jcdevapi.cierant.com/customize/" + personalizationData?.sku;
        axios
          .post(url, personalizationDataApi, {
            headers: {
              Authorization:
                "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
            },
          })
          .then((res) => {
            setimageLoading(false);
            return window.open(res.data.customizeURL, "_blank");
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    }
  };

  const getFinalData = async () => {
    if (personalizationData !== null && personalizationData !== undefined) {
      const isEmpty = personalizationData?.customizationData.find(
        (el) => el.pcodeValue !== ""
      );
      const imagesToPersonalize =
        personalizationData?.customizationImages.filter(
          (el) => el.pcodeValue.data !== null
        );
      if (
        (isEmpty !== null && isEmpty !== undefined) ||
        imagesToPersonalize.length > 0
      ) {
        const personalizationDataApi: PersonalizationData =
          cloneDeep(personalizationData);
        imagesToPersonalize?.forEach((el) => {
          const uploadedImage = uploadedImages.current.find(
            (img) => img.file === el.pcodeValue.data
          );
          if (
            uploadedImage !== null &&
            uploadedImage !== undefined &&
            uploadedImage.name !== ""
          ) {
            el.pcodeValue.mime = "text/plain";
            el.pcodeValue.data = uploadedImage.name;
          }
          personalizationDataApi?.customizationData.push(el);
        });

        personalizationDataApi.isPreview = false;
        personalizationDataApi.outputFormat = "pdf";

        const url =
          "https://jcdevapi.cierant.com/customize/" + personalizationData?.sku;
        const imageURL = await axios
          .post(url, personalizationDataApi, {
            headers: {
              Authorization:
                "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
            },
          })
          .then((res) => res.data)
          .catch((err) => {
            console.log("err: ", err);
          });

        const {
          orderId,
          sku,
          documentId,
          documentName,
          productId,
          productOptions,
        } = personalizationData;
        return {
          orderId,
          sessionId: "",
          sku,
          productId,
          documentId,
          documentName,
          customizeId: imageURL.customizationID,
          customizeURL: imageURL.customizeURL,
          isApproved: true,
          productOptions: productOptions.map((option: any) => {
            const answearData: any =
              personalizationData.customizationData.filter(
                (item) =>
                  item.pcodeName === option.pcode ||
                  item.pcodeName === option.name
              );
            const optionData = option.optionItems.filter(
              (item: any) => answearData[0].pcodeValue === item.value
            );

            return {
              optionId: option.OptionId,
              name: option.name,
              value: answearData[0]?.pcodeValue,
              cost: optionData[0]?.cost,
              isUnit: optionData[0]?.isUnit,
            };
          }),
        };
      }
    }
  };

  useEffect(() => {
    if (personalizationData !== null && personalizationData !== undefined) {
      const isEmpty = personalizationData?.customizationData.find(
        (el) => el.pcodeValue !== ""
      );
      const imagesToPersonalize =
        personalizationData?.customizationImages.filter(
          (el) => el.pcodeValue.data !== null
        );
      if (
        (isEmpty !== null && isEmpty !== undefined) ||
        imagesToPersonalize.length > 0
      ) {
        const personalizationDataApi: PersonalizationData =
          cloneDeep(personalizationData);
        imagesToPersonalize?.forEach((el) => {
          const uploadedImage = uploadedImages.current.find(
            (img) => img.file === el.pcodeValue.data
          );
          if (
            uploadedImage !== null &&
            uploadedImage !== undefined &&
            uploadedImage.name !== ""
          ) {
            el.pcodeValue.mime = "text/plain";
            el.pcodeValue.data = uploadedImage.name;
          }
          personalizationDataApi?.customizationData.push(el);
        });

        // if graphic mode only update background not text
        if (graphicMode) {
          const productComponentIds = productComponentList.map(
            (item) => item.id
          );
          const backgroundValues =
            personalizationDataApi.customizationData.filter((data) => {
              if (productComponentIds.includes(data.pcodeName)) {
                return data;
              }
              return null;
            });

          personalizationDataApi.customizationData = backgroundValues;
        }

        if (!graphicMode) {
          setimageLoading(true);
        }

        const url =
          "https://jcdevapi.cierant.com/customize/" + personalizationData?.sku;
        axios
          .post(url, personalizationDataApi, {
            headers: {
              Authorization:
                "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
            },
          })
          .then((res) => {
            if (
              res.data.customizeURL !== null &&
              res.data.customizeURL !== undefined &&
              res.data.customizeURL !== ""
            ) {
              setimageLoading(false);
              setImageUrl(res.data.customizeURL);
              setTemplateData({
                ...templateData,
                background: res.data.customizeURL,
              });
            }

            res.data.customImages.forEach((el: any) => {
              const imageToUpdate = imagesToPersonalize.find(
                (image: any) => image.pcodeName === el.pCode
              );
              if (imageToUpdate !== null && imageToUpdate !== undefined) {
                const imageIndex = uploadedImages.current.findIndex(
                  (img) => img.file === imageToUpdate.pcodeValue.data
                );
                if (imageIndex !== -1) {
                  uploadedImages.current[imageIndex].name = el.fileName;
                }
              }
            });
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    }
  }, [personalizationData, graphicMode, productComponentList]);

  const handleProf = () => {
    getImage();
  };

  const [finalData, setFinalData] = useState<any>();
  const handleSave = async () => {
    const tempData = await getFinalData();
    setFinalData(tempData);
    setShowCode(true);
  };

  return (
    <ErrorContext.Provider value={{ errorState, setErrorState }}>
      <CssBaseline />
      <EditorStyles />
      {loading ? (
        <LoadScreen />
      ) : (
        <Grid container spacing={2} className="wrapper">
          <Grid xs={12}>
            <Button onClick={() => setGraphicMode(!graphicMode)}>
              Switch Mode
            </Button>
          </Grid>
          {graphicMode ? (
            <GraphicEditor
              templateData={templateData}
              personalizationComponentList={personalizationComponentList}
              handleCallback={handleCallback}
              handleUploadImageCallback={handleUploadImageCallback}
              loading={imageLoading}
            />
          ) : (
            <ProductPreview imageUrl={imageUrl} loading={imageLoading} />
          )}
          {showCode ? (
            <RenderCode code={finalData} />
          ) : (
            <CustomizationPanel
              personalizationComponentList={personalizationComponentList}
              productsComponentList={productComponentList}
              handleCallback={handleCallback}
              handleCheckboxCallback={handleCheckboxCallback}
              handleUploadImageCallback={handleUploadImageCallback}
              handleProf={handleProf}
              handleSave={handleSave}
              finalizeData={finalizeData}
              graphicMode={graphicMode}
            />
          )}
        </Grid>
      )}
      <ErrorMessage message={errorState.message} />
    </ErrorContext.Provider>
  );
}

Editor.defaultProps = {
  data: mockDataEmpty,
};

export default Editor;
