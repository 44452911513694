import OptionItem from "../ProductPropertiesPanel/OptionItem";

export type props = {
  options: any;
  productOptions: any;
  updateOption: any;
};

const OptionsSelection = ({ options, productOptions, updateOption }: props) => {
  // Function to update list on drop
  const handleSelectedOption = (option: any) => {
    let temp = [...productOptions];
    const index = productOptions.findIndex(
      (productOption: any) => productOption.title === option.title
    );
    if (
      productOptions.filter(
        (productOption: any) => productOption.title === option.title
      ).length > 0
    ) {
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
      updateOption(temp);
    } else {
      temp.push(option);
      updateOption(temp);
    }
  };

  return (
    <div>
      {options.map((option: any, key: number) => (
        <OptionItem
          selected={
            productOptions.filter(
              (productOption: any) => productOption.title === option.title
            ).length > 0
          }
          value={option.title}
          key={1}
          onClick={() => handleSelectedOption(option)}
        />
      ))}
    </div>
  );
};

export default OptionsSelection;
