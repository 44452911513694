import Grid from "@mui/material/Grid";
import TextFieldInput from "../Input/TextFieldInput";
import DropdownInput from "../DropdownInput/DropdownInput";

export type props = {
  active: any;
  updateItem: (key: any, element: any) => void;
  items: any;
  template: any;
};

const Image = ({ active, updateItem, items, template }: props) => {
  const getValue = (id: any, value: any) => {
    const temp = { ...items[active], [id]: value };
    updateItem(active, temp);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextFieldInput
          id="label"
          title="Label"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="width"
          title="Width"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="height"
          title="Height"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="xpos"
          title="X"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          id="ypos"
          title="Y"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={12}>
        <DropdownInput
          id="pcodeId"
          title="Personalization Key"
          onChange={(id, value) =>
            getValue(
              id,
              template?.pcodes?.filter((pcode: any) => {
                return pcode.pcode === value;
              })[0].pcodeId
            )
          }
          value={
            template?.pcodes?.filter((pcode: any) => {
              return pcode.pcodeId === items[active].pcodeId;
            })[0].pcode
          }
          options={template?.pcodes?.map((pcode: any) => pcode.pcode)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          id="imageWidth"
          title="Image Width"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldInput
          id="imageHeight"
          title="Image Height"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={items[active]}
        />
      </Grid>
    </Grid>
  );
};

export default Image;
