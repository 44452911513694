import Tooltip from "@mui/material/Tooltip";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "../Button/Button";
import { StyledContainer } from "./styles";

export type props = {
  tab: number;
  addOption: any;
  selectedOption: number;
  deleteOption: (key: any) => void;
  options: any;
  productOptions: any;
  templates: any;
  products: any;
  getProductData: (data: void) => void;
  getTemplateData: (data: void) => void;
  setTab: (event: any, newValue: number) => void;
  selectOption: (element: any) => void;
  searchBarValue: string;
  updateSearchBar: (value: string) => void;
  previewProductReset: () => void;
};

const SearchBar = ({
  tab,
  addOption,
  selectedOption,
  deleteOption,
  options,
  products,
  templates,
  getProductData,
  getTemplateData,
  setTab,
  productOptions,
  selectOption,
  searchBarValue,
  updateSearchBar,
  previewProductReset,
}: props) => {
  const handleSave = () => {
    switch (tab) {
      case 0:
        console.log("save home");
        break;
      case 1:
        console.log("save product");
        break;
      case 2:
        console.log("save options");
        break;
      case 3:
        console.log("save template");
        break;
      default:
        console.log("save home");
        break;
    }
  };

  const handleDelete = () => {
    switch (tab) {
      case 0:
        console.log("delete home");
        break;
      case 1:
        console.log("delete product");
        if (selectedOption !== -1) {
          deleteOption(options[selectedOption].id);
        }
        break;
      case 2:
        console.log("delete options");
        if (selectedOption !== -1) {
          deleteOption(options[selectedOption].id);
        }
        break;
      case 3:
        console.log("delete template -> delete the template");
        break;
      default:
        console.log("delete home");
        break;
    }
  };

  const handleCopy = () => {
    switch (tab) {
      case 0:
        console.log("copy home");
        break;
      case 1:
        console.log("copy product -> copy the product");
        break;
      case 2:
        console.log("copy options");
        addOption(options[selectedOption]);
        break;
      case 3:
        console.log("copy template -> copy the template");
        break;
      default:
        console.log("copy home");
        break;
    }
  };

  const handleAdd = () => {
    switch (tab) {
      case 0:
        console.log("Add home");
        break;
      case 1:
        addOption({
          title: "Option Name",
          id: options.length,
          name: "Option Name",
          controlTypeId: "",
          description: "",
          controlTypes: [],
          isGraphic: false,
          items: [],
          pcodes: [],
        });
        break;
      case 2:
        addOption({
          title: "Option Name",
          id: options.length,
          name: "Option Name",
          controlTypeId: "",
          description: "",
          controlTypes: [],
          isGraphic: false,
          items: [],
          pcodes: [],
        });
        break;
      case 3:
        console.log("Add template -> Add another template");
        break;
      default:
        console.log("copy home");
        break;
    }
  };

  // WIP
  const handleOptions = () => {
    switch (tab) {
      case 0:
        return products.map((product: any, key: number) => {
          const { productName } = product;
          return { label: productName, key: key };
        });
      case 1:
        return products.map((product: any, key: number) => {
          const { productName } = product;
          return { label: productName, key: key };
        });
      case 2:
        return options.map((option: any, key: number) => {
          const { title } = option;
          return { label: title, key: key };
        });
      case 3:
        return templates.map((template: any, key: number) => {
          const { templateName } = template;
          return { label: templateName, key: key };
        });
      default:
        return [];
    }
  };

  const handleOptionsLabel = () => {
    switch (tab) {
      case 0:
        return "Select Product";
      case 1:
        return "Select Product";
      case 2:
        return "Select Option";
      case 3:
        return "Select Template";
      default:
        return "Select Product";
    }
  };

  const handleOnChange = (event: React.SyntheticEvent, value: any) => {
    switch (tab) {
      case 0:
        getProductData(products[value.key]);
        updateSearchBar(products[value.key].productName);
        setTab(event, 1);
        break;
      case 1:
        getProductData(products[value.key]);
        updateSearchBar(products[value.key].productName);
        break;
      case 2:
        selectOption(value.key);
        updateSearchBar(productOptions[value.key].title);
        break;
      case 3:
        getTemplateData(templates[value.key]);
        updateSearchBar(templates[value.key].templateName);
        //previewProductReset();
        break;
      default:
        return "Product options";
    }
  };

  return (
    <StyledContainer>
      <div className="container">
        <div className="search-container">
          <Autocomplete
            id="disabled-options-demo"
            options={handleOptions()}
            renderInput={(params) => (
              <TextField {...params} label={handleOptionsLabel()} />
            )}
            onChange={(e, value) => handleOnChange(e, value)}
            value={searchBarValue}
          />
        </div>
        <div className="button-container">
          <Button onClick={handleAdd} disabled={tab === 0}>
            <Tooltip title="New">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button onClick={handleSave}>
            <Tooltip title="Save">
              <SaveAltIcon />
            </Tooltip>
          </Button>
          <Button onClick={handleCopy} disabled={tab === 0}>
            <Tooltip title="Copy">
              <ContentCopyIcon />
            </Tooltip>
          </Button>
          <Button onClick={handleDelete} disabled={tab === 0}>
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </div>
      </div>
    </StyledContainer>
  );
};

export default SearchBar;
