import React from "react";
import { StyledImage } from "./styles";
export type props = {
  children?: React.ReactNode;
  onClick: () => void;
  active?: boolean;
};
const ImageElement = ({ children, onClick, active }: props) => {
  return (
    <StyledImage active={active} onClick={onClick}>
      {children}
    </StyledImage>
  );
};

export default ImageElement;
