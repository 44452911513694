import styled from "@emotion/styled";
import Theme from "../../base/theme";
import Dialog from "@mui/material/Dialog";

export const StyledContainer = styled.div`
  background-color: ${Theme.palette.grey[100]};
  padding: ${Theme.spacing(3)} ${Theme.spacing(2)};
  text-align: center;
  border-radius: ${Theme.shape.borderRadius}px;

  .title-divider {
    margin: ${Theme.spacing(2)} 0;
  }
`;

export const StyledOption = styled.div`
  .option {
    border-bottom: 1px solid ${Theme.palette.grey[300]};
    margin-bottom: ${Theme.spacing(4)};
  }

  .item-list {
    margin-top: 0;
  }
`;

export const Optionlist = styled.div`
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  width: 100%;
`;

export const StyledDialog = styled((props: any) => {
  return <Dialog {...props} />;
})(({ theme }) => ({
  "* .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    width: "100%",
    maxWidth: "80%",
    padding: "20px",
  },
  ".left-menu": {
    button: {
      width: "100%",
      textAlign: "left",
      justifyContent: "flex-start",
    },
  },
  ".right-panel": {
    paddingLeft: "40px",
    paddingTop: "40px",
  },
  ".button-container": {
    display: "flex",
  },
}));
