import { Container, StyledGrid } from "./styles";
import Element from "./Element";
import UploadImage from "../UploadImage/UploadImage";
import TextArea from "../TextArea/TextArea";
import LoadScreen from "../loader/LoadScreen";

export type props = {
  templateData: {
    background: string;
    colors?: [];
    fonts?: [];
    height: number;
    width: number;
  };
  personalizationComponentList: any;
  handleCallback: any;
  handleUploadImageCallback: any;
  loading: boolean;
};

const GraphicEditor = ({
  templateData,
  personalizationComponentList,
  handleCallback,
  handleUploadImageCallback,
  loading,
}: props) => {
  return (
    <StyledGrid item xs={12} md={6}>
      <Container>
        <div
          className="bar"
          style={{
            width: templateData.width,
            height: templateData.height,
          }}
        >
          <img
            src={templateData.background}
            alt="template-background"
            className="template-background"
          />
          {personalizationComponentList.map((component: any, key: number) => {
            const {
              defaultText,
              fontColor,
              fontFamily,
              fontSize,
              height,
              id,
              pxHeight,
              pxWidth,
              textAlign,
              title,
              type,
              width,
              x,
              y,
              maxlength,
            } = component;

            if (type === "text") {
              return (
                <Element
                  initialPosition={{ x, y }}
                  initialSize={{ width, height }}
                  editable={false}
                >
                  <TextArea
                    key={key}
                    id={id}
                    defaultText={defaultText}
                    callback={handleCallback}
                    maxlength={maxlength}
                    style={{
                      borderColor: fontColor,
                      color: fontColor,
                      fontFamily,
                      fontSize,
                      textAlign,
                      width,
                      height,
                    }}
                  />
                </Element>
              );
            }
            if (type === "image") {
              return (
                <Element
                  initialPosition={{ x, y }}
                  initialSize={{ width: pxWidth, height: pxHeight }}
                >
                  <UploadImage
                    key={key}
                    id={id}
                    title={title}
                    width={width}
                    height={height}
                    graphicMode
                    callback={handleUploadImageCallback}
                  />
                </Element>
              );
            }
            return null;
          })}
        </div>
        {loading && <LoadScreen opacity />}
      </Container>
    </StyledGrid>
  );
};

export default GraphicEditor;
