import { useState, useEffect, memo } from "react";
import Label from "../Label/Label";
import FormGroup from "../FormGroup/FormGroup";
import { StyledInput, StyledCount } from "./styles";
interface TextFieldInputProps {
  callback?: (id: string, value: string) => void;
  controlledValue?: string;
  defaultText?: string;
  id: string;
  maxLength?: number;
  multiline?: boolean;
  onChange?: (id: string, value: string) => void;
  title?: string;
  disabled?: boolean;
}

const TextFieldInput = ({
  id,
  title,
  defaultText,
  callback,
  maxLength,
  multiline = true,
  onChange,
  controlledValue,
  disabled,
}: TextFieldInputProps) => {
  const [value, setValue] = useState("");

  const textChange = (event: any) => {
    if (maxLength) {
      if (event.target.value.length <= maxLength) {
        setValue(event.target.value);
      }
    } else {
      setValue(event.target.value);
    }
  };

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(id, e.target.value);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== null && value !== undefined && value !== "") {
        if (callback) {
          callback(id, value);
        }
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value, id, callback]);

  let length;
  if (value.length === 0 && defaultText) {
    length = defaultText.length;
  } else {
    length = value.length;
  }
  return (
    <FormGroup>
      {title && <Label id="text-field-label">{title}:</Label>}
      <StyledInput
        id="text-field"
        className={"without-padding"}
        fullWidth
        multiline={multiline}
        rows={4}
        defaultValue={defaultText}
        onChange={onChange ? handleChange : textChange}
        value={
          // @ts-ignore: Unreachable code error
          (controlledValue ? controlledValue[id] : value) || defaultText
        }
        disabled={disabled}
      />
      {maxLength && (
        <StyledCount>{`Characters remaining: ${
          maxLength - length
        }`}</StyledCount>
      )}
    </FormGroup>
  );
};

export default memo(TextFieldInput);
