export const FONTS = ["Arial", "Helvetica", "Times New Roman", "Courier New"];
export const COLORS = ["Red", "Blue", "Pink", "Yellow", "Orange", "Green"];
export const ALIGNMENTS = ["left", "right", "center"];

export const itemData = {
  text: {
    type: "text",
    label: "Enter the label",
    defaultText: "",
    width: 100,
    height: 100,
    xpos: 0,
    ypos: 0,
    personalizationKey: 1,
    fontId: { fontId: 1000, font: "Arial" },
    fontSize: 15,
    fontColorId: { fontColorId: 1000, fontColor: "Black" },
    textAlign: "left",
    charLimit: 100,
  },
  image: {
    type: "image",
    name: "",
    width: 100,
    height: 100,
    xpos: 0,
    ypos: 0,
    imageWidth: 2,
    imageHeight: 2,
    pcodeId: 1,
    id: 1,
  },
};

export const searchOptionsData = {
  home: [],
  product: [
    {
      label: "Graduation Chocolate Bar Photo Class Of",
      id: 1,
    },
    {
      label: "Graduation Chocolate Bar Photo Class Of 2",
      id: 2,
    },
    {
      label: "Graduation Chocolate Bar Photo Class Of 3",
      id: 3,
    },
  ],
  options: [],
  template: [
    {
      label: "Template 1",
      id: 1,
    },
    {
      label: "Template 2",
      id: 2,
    },
    {
      label: "Template 3",
      id: 3,
    },
  ],
};

export const initData = {
  marketplaceId: "664C9D20-101D-4F17-B48E-0402F3A93BDC",
  productId: "E85D2FC4-201D-4E5D-BE52-192BAD0D9BBA",
  sku: "JCGR0001-SB",
  documentName: "JCGR0001-SB",
  documentId: "24124",
  productName: "Graduation Chocolate Bar Photo Class Of",
  productDescription:
    "Includes: Your choice of completely assembled Hershey's Milk Chocolate Bar with Silver or Gold Foil, Hershey's Special Dark Chocolate Bar, Nestle's Crunch Bar with Silver Foil, or 3oz Belgian Milk Chocolate Bar with Silver Foil",
  productImage:
    "https://jcandy-weblinc.netdna-ssl.com/product_images/jcgr0001-sb-graduation-personalized-chocolate-bar-photo-class-of/blue/62023545736964369a0002d9/detail.jpg?c=1644484139",
  background: "https://editdemo.cierant.com/images/JCGR0001-SB_background.png",
  uiTemplateId: 1000,
  productOptions: [
    {
      OptionId: 1000,
      DisplayOrder: 1,
      title: "Bar",
      description: "Make Your Selection",
    },
    {
      OptionId: 1001,
      DisplayOrder: 2,
      title: "Color",
      description: "Select your school color.",
    },
    {
      OptionId: 1002,
      DisplayOrder: 3,
      title: "Back Design",
      description: "Click Below To View And Select",
    },
  ],
};
