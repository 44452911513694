import Grid from "@mui/material/Grid";
import TextFieldInput from "../Input/TextFieldInput";
import DropdownInput from "../DropdownInput/DropdownInput";
import Switch from "../Switch/Switch";

export type props = {
  option: any;
  updateOption: (element: any) => void;
};

const OptionsForm = ({ option, updateOption }: props) => {
  const getValue = (id: any, value: any) => {
    const temp = { ...option };
    temp[id] = value;
    updateOption(temp);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          id="title"
          title="Title"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={option}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownInput
          id="controlTypeId"
          title="Control Type"
          onChange={(id, value) => {
            getValue(
              id,
              option?.controlTypes?.filter(
                (type: any) => type.controlType === value
              )[0].controlTypeId
            );
          }}
          value={
            option?.controlTypes?.filter(
              (type: any) => type.controlTypeId === option?.controlTypeId
            )[0].controlType
          }
          options={option?.controlTypes?.map((type: any) => type.controlType)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          id="description"
          title="Description"
          multiline={false}
          onChange={(id, value) => getValue(id, value)}
          controlledValue={option}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          id="name"
          title="Control Name"
          multiline={false}
          onChange={(id, value) =>
            getValue(
              id,
              option?.pcodes?.filter((type: any) => type.pcodeId === value)[0]
                .pcodeId
            )
          }
          controlledValue={option}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownInput
          id="pcodeId"
          title="Personalization Key"
          onChange={(id, value) => getValue(id, value)}
          value={
            option?.pcodes?.filter(
              (type: any) => type.pcodeId === option?.pcodeId || ""
            )[0]?.pcode
          }
          options={option?.pcodes?.map((pcode: any) => pcode.pcode)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Switch
          id="isGraphic"
          label="Graphic"
          onChange={(id, value) => getValue(id, value)}
          value={option}
        />
      </Grid>
    </Grid>
  );
};

export default OptionsForm;
