import styled from "@emotion/styled";
import Theme from "../../base/theme";

export const StyledContainer = styled.div`
  background-color: ${Theme.palette.grey[50]};
  padding: ${Theme.spacing(3)} ${Theme.spacing(3)};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (min-width: ${Theme.breakpoints.values.md}px) {
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    top: 169px;
    width: 80px;
  }

  .title-divider {
    margin: ${Theme.spacing(2)} 0;
  }
`;

export const StyledComponentContainer = styled.div`
  background-color: ${Theme.palette.grey[100]};
  padding: ${Theme.spacing(2)};
  margin-bottom: ${Theme.spacing(2)};
  text-align: center;
  border-radius: ${Theme.shape.borderRadius}px;
  border: 1px solid ${Theme.palette.grey[500]};

  &:hover {
    border: 1px solid ${Theme.palette.grey[700]};
  }

  img {
    width: 100%;
    margin-bottom: ${Theme.spacing(1)};
  }

  p{
    font-size ${Theme.typography.pxToRem(12)}
  }
`;
