import styled from "@emotion/styled";
import Theme from "../../base/theme";

export const StyledContainer = styled.div`
  background-color: ${Theme.palette.grey[100]};
  padding: ${Theme.spacing(3)} ${Theme.spacing(2)};
  text-align: center;
  border-radius: ${Theme.shape.borderRadius}px;

  .title-divider {
    margin: ${Theme.spacing(2)} 0;
  }
`;

export const StyledOption = styled.div`
  background-color: ${Theme.palette.grey[100]};
  padding: ${Theme.spacing(2)};
  margin-bottom: ${Theme.spacing(2)};
  text-align: center;
  border-radius: ${Theme.shape.borderRadius}px;
  border: 1px solid ${Theme.palette.grey[300]};

  &:hover {
    border: 1px solid ${Theme.palette.grey[500]};
  }
  p{
    font-size ${Theme.typography.pxToRem(12)}
  }
  
  .items{
    padding: ${Theme.spacing(3)};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Theme.spacing(3)} 0;
  margin-bottom: ${Theme.spacing(3)};
  border-bottom: 1px solid ${Theme.palette.grey[300]};

  button {
    margin-left: ${Theme.spacing(2)};
    padding: ${Theme.spacing(2)};
    min-width: 0;
  }
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${Theme.spacing()} ${Theme.spacing(3)};
  margin: 0 ${Theme.spacing(4)} ${Theme.spacing(4)};
  height: 100%;

  .title-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: ${Theme.spacing(3)};

    button {
      min-width: 0px;
      padding: ${Theme.spacing(1)};
    }

    label {
      margin-bottom: 0;
    }
  }

  .option-list {
    border: 1px solid ${Theme.palette.grey[300]};
    height: 100%;
  }
`;

export const OptionsContainer = styled.div`
  border: 1px solid ${Theme.palette.grey[300]};
  height: 100%;
  margin-top: ${Theme.spacing(3)};
`;

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;

  .icon,
  svg {
    position: absolute;
    right: 10px;
  }
`;
