import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider } from "@mui/material";

export type props = {
  value: any;
  onClick: () => void;
  selected?: boolean;
  deleteOption: any;
  onEditClick: (e?: any) => void;
};

const OptionItem = ({
  value,
  onClick,
  selected,
  deleteOption,
  onEditClick,
}: props) => {
  const handleDelete = (e: any) => {
    e.stopPropagation();
    deleteOption();
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    onClick();
    onEditClick();
  };
  const labelId = `checkbox-list-label-${value}`;
  return (
    <>
      <ListItem
        key={value}
        secondaryAction={
          <IconButton edge="end" aria-label="comments">
            <EditIcon onClick={handleEdit} />
          </IconButton>
        }
        onClick={onClick}
        disablePadding
        selected={selected}
      >
        <ListItemButton role={undefined} dense>
          <ListItemText id={labelId} primary={value} />
          <ListItemIcon>
            <DeleteIcon onClick={handleDelete} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};

export default OptionItem;
