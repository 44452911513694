import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

export const StyledAutocomplete = styled((props: any) => (
  <Autocomplete {...props} />
))(({ theme, multiline }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  padding: 0,
  "& input": {
    padding: multiline ? theme.spacing(1) : theme.spacing(1),
  },
  "& > div > div": {
    padding: "0 !important",
  },
  "& .css-1q60rmi-MuiAutocomplete-endAdornment": {
    top: "calc(50% - 20px)",
  },
}));

export const StyledInput = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme, multiline }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  "& input": {
    padding: multiline ? theme.spacing(1) : theme.spacing(1),
  },
}));
