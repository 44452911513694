import { Switch as MUISwitch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

type props = {
  id: string;
  onChange: (id: any, value: any) => void;
  value: any;
  label: any;
};

const Switch = ({ id, onChange, value, label }: props) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange(id, e.target.checked);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <MUISwitch
            id={id}
            onChange={handleChange}
            value={value[id]}
            checked={value[id]}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default Switch;
