import styled from "@emotion/styled";
import Theme from "../../base/theme";

export const StyledContainer = styled.div`
  display: flex;
  margin-bottom: ${Theme.spacing(4)};
  padding: ${Theme.spacing(3)} ${Theme.spacing(3)};
  background-color: ${Theme.palette.grey[100]};

  .container {
    display: flex;
    max-width: ${Theme.breakpoints.values.xl}px;
    margin: auto;
    width: 100%;

    & > div {
      width: 50%;
      display: flex;

      & > div {
        width: 80%;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .search-container {
    input {
      padding: 0 !important;
    }
  }
`;
