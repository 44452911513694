import Grid from "@mui/material/Grid";
import TextFieldInput from "../Input/TextFieldInput";

export type props = {
  homeProperties: any;
  updateHome: (element: any) => void;
};

const Home = ({ homeProperties, updateHome }: props) => {
  const getValue = (id: any, value: any) => {
    const temp = { ...homeProperties, [id]: value };
    updateHome(temp);
  };

  return (
    <>
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            id="clientName"
            title="Client"
            multiline={false}
            onChange={(id, value) => getValue(id, value)}
            controlledValue={homeProperties}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            id="clientApp"
            title="Application"
            multiline={false}
            onChange={(id, value) => getValue(id, value)}
            controlledValue={homeProperties}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            id="marketplaceId"
            title="Marketplace Id"
            onChange={(id, value) => getValue(id, value)}
            multiline={false}
            controlledValue={homeProperties}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            id="proofMessage"
            title="Proof Message"
            onChange={(id, value) => getValue(id, value)}
            controlledValue={homeProperties}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            id="approvalMessage"
            title="Approve Message"
            onChange={(id, value) => getValue(id, value)}
            controlledValue={homeProperties}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
