import React, { useEffect, useReducer } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { StyledContainer } from "./styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContent from "../components/TabContent/TabContent";
import SearchBar from "../components/SearchBar/SearchBar";

import { itemData, initData } from "./itemData";

const initialState = {
  searchBarValue: "",
  tab: 0,
  active: -1,
  optionsPane: false,
  items: [],
  selectedOption: null,
  template: {
    uiTemplateName: "",
    width: "0",
    height: "0",
    fontFamily: "",
    fonts: [
      { font: "Arial", fontId: 1000 },
      { font: "Garamond", fontId: 1001 },
      { font: "Futura", fontId: 1002 },
      { font: "Montserrat", fontId: 1003 },
      { font: "DIN Condensed", fontId: 1004 },
      { font: "Minon Pro", fontId: 1005 },
    ],
    selectedFonts: [],
    fontsColors: [
      { fontColor: "Black", fontColorId: 1000 },
      { fontColor: "White", fontColorId: 1001 },
    ],
    selectedColors: [],
    selectedProduct: { productName: "" },
  },
  productProperties: {
    sku: "",
    productName: "",
    productId: "",
    productImage: "",
    background: "",
    productDescription: "",
    uiTemplateId: "",
  },
  homeProperties: {
    clientName: "",
    clientApp: "",
    marketplaceId: "",
    proofMessage: "",
    approvalMessage: "",
  },
  productOptions: [],
  options: [],
  products: [],
  templates: [],
};

function reducer(state: any, action: any) {
  let temp;
  switch (action.type) {
    case "LOAD_INITIAL_DATA":
      const {
        productOptions,
        background,
        productImage,
        productName,
        documentId,
        productDescription,
        sku,
      } = initData;
      temp = { ...state };
      temp.options = productOptions.map((option, key) => {
        const { DisplayOrder, OptionId, description, title } = option;
        return {
          id: OptionId,
          title,
          name: "Option Name",
          controlTypeId: "",
          description,
          controlTypes: [],
          isGraphic: false,
          items: [],
          pcodes: [],
          displayOrder: DisplayOrder,
        };
      });
      temp.productProperties = {
        sku,
        name: productName,
        id: documentId,
        productImage: productImage,
        bg: background,
        description: productDescription,
        graphicEditorTemplate: "",
      };
      return temp;
    case "LOAD_HOME_DATA":
      const {
        approvalMessage,
        clientApp,
        clientName,
        marketplaceId,
        options,
        products,
        proofMessage,
        templates,
      } = action.payload;
      temp = { ...state, products, templates };
      temp.options = options.map((option: any, key: number) => {
        const { optionId, description, title } = option;
        return {
          optionId,
          id: optionId,
          title,
          name: "Option Name",
          controlTypeId: "",
          description,
          controlTypes: [],
          isGraphic: false,
          items: [],
          pcodes: [],
          displayOrder: "",
        };
      });
      temp.homeProperties = {
        clientName,
        clientApp,
        marketplaceId,
        proofMessage,
        approvalMessage,
      };
      return temp;
    case "LOAD_PRODUCT_DATA":
      const {
        productId: productProductId,
        sku: productSku,
        documentName: productDocumentName,
        documentId: productDocumentId,
        productName: productProductName,
        productDescription: productProductDescription,
        productImage: productProductImage,
        background: productBackground,
        uiTemplateId: productUiTemplateId,
        productOptions: tempOptions,
      } = action.payload;
      temp = { ...state };
      const filteredOptions: any[] = [];
      tempOptions.map((option: any, key: number) => {
        state.options.filter((item: any) => {
          if (item.optionId === option.optionId) {
            filteredOptions.push(item);
          }
        });
      });
      temp.productOptions = filteredOptions;
      temp.productProperties = {
        productId: productProductId,
        sku: productSku,
        documentName: productDocumentName,
        documentId: productDocumentId,
        productName: productProductName,
        productDescription: productProductDescription,
        productImage: productProductImage,
        background: productBackground,
        uiTemplateId: productUiTemplateId,
      };
      return temp;
    case "LOAD_TEMPLATE_DATA":
      const {
        fonts,
        fontsColors,
        height,
        width,
        uiTemplateName,
        pZone,
        selectedFonts,
        selectedColors,
        products: templateProducts,
        pcodes,
      } = action.payload;
      temp = { ...state };
      temp.template = {
        uiTemplateName,
        width,
        height,
        fontFamily: "",
        color: "",
        fonts,
        fontsColors,
        selectedFonts,
        selectedColors,
        products: templateProducts,
        pcodes,
        selectedProduct: { productName: "" },
      };
      temp.items = pZone.map((item: any, key: number) => {
        const {
          height,
          id,
          imageHeight,
          imageWidth,
          name,
          pcodeId,
          width,
          xpos,
          ypos,
          charLimit,
          defaultText,
          fontColorId,
          fontId,
          fontSize,
          textAlign,
        } = item;
        if (pZone[key].name === "Photo") {
          return {
            name,
            pcodeId,
            id,
            type: "image",
            width,
            height,
            xpos,
            ypos,
            imageWidth,
            imageHeight,
          };
        } else {
          return {
            type: "text",
            defaultText,
            width,
            height,
            xpos,
            ypos,
            personalizationKey: 1,
            fontId: { fontId: fontId },
            fontSize,
            fontColorId: { fontColorId: fontColorId },
            textAlign,
            charLimit,
            pcodeId,
          };
        }
      });
      return temp;
    case "SELECT_OPTION":
      return { ...state, selectedOption: action.payload };
    case "SET_TAB":
      return { ...state, tab: action.payload };
    case "SET_ACTIVE":
      return { ...state, active: action.payload };
    case "TOGGLE_OPTIONS_PANE":
      return { ...state, optionsPane: !state.optionsPane };
    case "ADD_ITEM":
      return { ...state, items: [...state.items, action.payload] };
    case "UPDATE_ITEM":
      temp = { ...state };
      temp.items[action.payload.key] = action.payload.item;
      return temp;
    case "UPDATE_TEMPLATE":
      return { ...state, template: action.payload.item };
    case "UPDATE_PRODUCT_PROPERTIES":
      return { ...state, productProperties: action.payload.item };
    case "UPDATE_HOME":
      return { ...state, homeProperties: action.payload.item };
    case "ADD_OPTIONS":
      return {
        ...state,
        options: [...state.options, action.payload.item],
      };
    case "UPDATE_OPTION":
      temp = { ...state };
      temp.options[state.selectedOption] = action.payload.item;
      return temp;
    case "UPDATE_OPTION_BY_KEY":
      temp = { ...state };
      temp.options[action.payload.index] = action.payload.item;
      return temp;
    case "UPDATE_OPTIONS_ORDER":
      temp = { ...state };
      temp.productOptions = action.payload.options;
      return temp;
    case "DELETE_OPTION":
      temp = { ...state };
      temp.options = [
        ...state.options.slice(0, action.payload.key),
        ...state.options.slice(action.payload.key + 1),
      ];
      return temp;
    case "ADD_PRODUCT_OPTIONS":
      return {
        ...state,
        productOptions: [...state.productOptions, action.payload.item],
      };
    case "UPDATE_PRODUCT_OPTION":
      temp = { ...state };
      temp.productOptions = action.payload.item;
      return temp;
    case "UPDATE_PRODUCT_OPTIONS_ORDER":
      temp = { ...state };
      temp.productOptions = action.payload.options;
      return temp;
    case "DELETE_PRODUCT_OPTION":
      temp = { ...state };
      temp.productOptions = [
        ...state.productOptions.slice(0, action.payload.key),
        ...state.productOptions.slice(action.payload.key + 1),
      ];
      return temp;
    case "DELETE_ITEM":
      temp = { ...state };
      temp.productOptions = [
        ...state.productOptions.slice(0, action.payload.key),
        ...state.productOptions.slice(action.payload.key + 1),
      ];
      temp.active = -1;
      return temp;
    case "UPDATE_SEARCH_BAR_VALUE":
      return { ...state, searchBarValue: action.payload };
    case "PREVIEW_PRODUCT_RESET":
      temp = { ...state.template, selectedProduct: { productName: "" } };
      return { ...state, template: temp };
    default:
      throw new Error();
  }
}

const Builder = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setActive = (key: number, element: any) => {
    if (element === "template") {
      dispatch({
        type: "SET_ACTIVE",
        payload: { type: "template" },
      });
    } else {
      dispatch({
        type: "SET_ACTIVE",
        payload: key,
      });
    }
  };

  const addItem = (item: any) => {
    let defaultData;
    if (item === "Text") {
      defaultData = itemData.text;
    } else {
      defaultData = itemData.image;
    }
    dispatch({ type: "ADD_ITEM", payload: defaultData });
  };

  const updateItem = (key: any, item: any) => {
    dispatch({ type: "UPDATE_ITEM", payload: { key, item } });
  };

  const deleteItem = (key: any) => {
    dispatch({ type: "DELETE_ITEM", payload: { key } });
  };

  const updateTemplate = (item: any) => {
    dispatch({ type: "UPDATE_TEMPLATE", payload: { item } });
  };

  const updateProductProperties = (item: any) => {
    dispatch({ type: "UPDATE_PRODUCT_PROPERTIES", payload: { item } });
  };

  const addOption = (item: any) => {
    dispatch({ type: "ADD_OPTIONS", payload: { item } });
  };

  const updateOption = (item: any) => {
    const index = state.options.findIndex(
      (option: any) => option.id === state.selectedOption
    );
    dispatch({ type: "UPDATE_OPTION_BY_KEY", payload: { item, index: index } });
  };

  const updateOptionInital = (item: any) => {
    const index = state.options.findIndex(
      (option: any) => option.id === state.selectedOption
    );
    dispatch({ type: "UPDATE_OPTION", payload: { item, index } });
  };

  const deleteOption = (key: any) => {
    dispatch({ type: "DELETE_OPTION", payload: { key } });
  };

  const addProductOption = (item: any) => {
    dispatch({ type: "ADD_PRODUCT_OPTIONS", payload: { item } });
  };

  const updateProductOption = (item: any) => {
    dispatch({ type: "UPDATE_PRODUCT_OPTION", payload: { item } });
  };

  const deleteProductOption = (key: any) => {
    dispatch({ type: "DELETE_PRODUCT_OPTION", payload: { key } });
  };

  const toggleOptionPane = () => {
    dispatch({ type: "TOGGLE_OPTIONS_PANE" });
  };

  const setTab = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: "SET_TAB", payload: newValue });
  };

  const selectOption = (key: any) => {
    dispatch({ type: "SELECT_OPTION", payload: key });
  };

  const updateHome = (item: any) => {
    dispatch({ type: "UPDATE_HOME", payload: { item } });
  };

  const updateOptionsOrder = (options: any) => {
    dispatch({
      type: "UPDATE_OPTIONS_ORDER",
      payload: {
        options: options.map((option: any, key: number) => {
          return { ...option, displayOrder: key };
        }),
      },
    });
  };

  const updateSearchBar = (value: string) => {
    dispatch({ type: "UPDATE_SEARCH_BAR_VALUE", payload: value });
  };

  const previewProductReset = () => {
    dispatch({ type: "PREVIEW_PRODUCT_RESET" });
  };

  const getProductData = async (data: any) => {
    const url = "https://jcdevapi.cierant.com/admin/product";

    const productData = await axios
      .get(url, {
        params: {
          marketplaceId: "664C9D20-101D-4F17-B48E-0402F3A93BDC",
          sku: data.sku,
        },
        headers: {
          Authorization:
            "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
        },
      })
      .then((res: any) => JSON.parse(res.data)[0])
      .catch((error) => {
        console.log("error: ", error);
      });

    dispatch({ type: "LOAD_PRODUCT_DATA", payload: productData });

    productData.options.map((option: any, key: number) =>
      getOptionData(option, key)
    );
  };

  const getTemplateData = async (data: any) => {
    const url = "https://jcdevapi.cierant.com/admin/template";

    const templateData = await axios
      .get(url, {
        params: {
          marketplaceId: "664C9D20-101D-4F17-B48E-0402F3A93BDC",
          templateId: data.templateId,
        },
        headers: {
          Authorization:
            "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
        },
      })
      .then((res: any) => JSON.parse(res.data)[0])
      .catch((error) => {
        console.log("error: ", error);
      });

    dispatch({ type: "LOAD_TEMPLATE_DATA", payload: templateData });
  };

  const getOptionData = (option: any, key: number) => {
    const url = "https://jcdevapi.cierant.com/admin/option";

    axios
      .get(url, {
        params: {
          marketplaceId: "664C9D20-101D-4F17-B48E-0402F3A93BDC",
          optionId: option.optionId,
        },
        headers: {
          Authorization:
            "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
        },
      })
      .then((res: any) => JSON.parse(res.data)[0])
      .then((res) => {
        selectOption(key);
        const {
          id,
          controlTypeId,
          controlTypes,
          description,
          isGraphic,
          name,
          optionItems,
          pcodes,
          title,
          pcodeId,
        } = res;
        updateOptionInital({
          optionId: id,
          title,
          id: key,
          name,
          controlTypeId,
          description,
          controlTypes,
          isGraphic,
          items: optionItems,
          pcodes,
          pcodeId: pcodeId || "",
        });
      })
      .then(() => selectOption(null))
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    const getHomeData = async () => {
      const url =
        "https://jcdevapi.cierant.com/admin/initialize/664C9D20-101D-4F17-B48E-0402F3A93BDC";
      const homeData = await axios
        .get(url, {
          headers: {
            Authorization:
              "Basic MTAyOjg4MTQ4MjhGLUZCNEUtNDhGNC05OUQwLTU2RTlFQUJDNDAxNw==",
          },
        })
        .then((res) => JSON.parse(res.data)[0])
        .catch((err) => {
          console.log("err: ", err);
        });

      dispatch({ type: "LOAD_HOME_DATA", payload: homeData });
      homeData.options.map((option: any, key: number) =>
        getOptionData(option, key)
      );
    };

    getHomeData();
  }, [initData]);

  return (
    <StyledContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchBar
            tab={state.tab}
            addOption={addOption}
            selectedOption={state.selectedOption}
            deleteOption={deleteOption}
            options={state.options}
            templates={state.templates}
            products={state.products}
            getProductData={getProductData}
            getTemplateData={getTemplateData}
            setTab={setTab}
            productOptions={state.productOptions}
            selectOption={selectOption}
            searchBarValue={state.searchBarValue}
            updateSearchBar={updateSearchBar}
            previewProductReset={previewProductReset}
          />
        </Grid>
        <Grid item xs={12} className="header">
          <div className="custom-container">
            <Tabs
              value={state.tab}
              onChange={(e, key) => {
                setTab(e, key);
                updateSearchBar("");
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Home" />
              <Tab label="Product" />
              <Tab label="Option" />
              <Tab label="Template" />
              <Tab label="Saved Data" />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} className="content">
          <div className="custom-container">
            <TabContent
              active={state.active}
              tab={state.tab}
              product={state.productProperties}
              updateProduct={updateProductProperties}
              addItem={addItem}
              productProperties={state.productProperties}
              setActive={setActive}
              items={state.items}
              updateItem={updateItem}
              template={state.template}
              updateTemplate={updateTemplate}
              options={state.options}
              addOption={addOption}
              updateOption={updateOption}
              selectOption={selectOption}
              selectedOption={state.selectedOption}
              deleteOption={deleteOption}
              toggleOptionPane={toggleOptionPane}
              setTab={setTab}
              homeProperties={state.homeProperties}
              updateHome={updateHome}
              updateOptionsOrder={updateOptionsOrder}
              deleteItem={deleteItem}
              templates={state.templates}
              productOptions={state.productOptions}
              addProductOption={addProductOption}
              updateProductOption={updateProductOption}
              deleteProductOption={deleteProductOption}
              code={state}
            />
          </div>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Builder;
